
import { defineComponent, ref } from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import { Field } from "vee-validate";
import * as Yup from "yup";
import UserService from "@/core/services/UserService";

export default defineComponent({
  name: "PresetMfaForm",
  components: {FormErrorMessage, BaseForm, Field},
  props: {
    id: {type: String}
  },
  emits: ['savedMFA'],
  setup(props, {emit}) {
    const submitting = ref(false);
    const model = ref({mfa: ''});
    const validate = Yup.object().shape({
      mfa: Yup.string().required().length(6).label("MFA"),
    });
    const submit = () => {
      submitting.value = true
      UserService.presetMfa(props.id, model.value).then(res => {
        emit('savedMFA', res);
      }).finally(() => {
        submitting.value = false;
      })
    }
    return {
      model,
      submitting,
      validate,
      submit
    }
  }
})
