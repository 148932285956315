
import { defineComponent, ref } from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import { Field } from "vee-validate";
import * as Yup from "yup";
import UserService from "@/core/services/UserService";

export default defineComponent({
  name: "ForceUpdatePassword",
  components: {FormErrorMessage, BaseForm, Field},
  props: {
    id: {type: String}
  },
  emits: ['saved'],
  setup(props, {emit}) {
    const submitting = ref(false);
    const model = ref({password: ''});
    const validate = Yup.object().shape({
      mfa: Yup.string().required().label("Password"),
    });
    const submit = () => {
      submitting.value = true
      UserService.forceUpdatePassword(props.id, model.value).then(res => {
        emit('saved', res);
        model.value.password = ''
      }).finally(() => {
        submitting.value = false;
      })
    }
    return {
      model,
      submitting,
      validate,
      submit
    }
  }
})
