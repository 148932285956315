
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {computed, defineComponent, onUpdated, reactive, ref} from "vue";
import {LoadEntity, userRoleCheck} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import QuickAction from "@/components/base/action/QuickAction.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import TabNavigate from "@/components/base/common/TabNavigate.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import FormUser from "@/views/user/FormUser.vue";
import UserService from "@/core/services/UserService";
import Swal from 'sweetalert2'
import PresetMfaForm from "@/views/user/PresetMfaForm.vue";
import UserView from "@/components/base/common/UserView.vue";
import ForceUpdatePassword from "@/views/user/ForceUpdatePassword.vue";
import UserStatus from "@/views/user/UserStatus.vue";

export default defineComponent({
  name: "User",
  components: {
    UserStatus,
    ForceUpdatePassword,
    UserView, PresetMfaForm, FormUser, BaseModal, TabNavigate, DateTimeFormat, QuickAction
  },
  setup() {
    const code = ref('')

    onUpdated(() => {

      setCurrentPageBreadcrumbsWithParams('User Details', [
        {link: true, router: '/users', text: 'Users'},
        {link: false, router: '', text: 'View User'}
      ])
    })

    const state = reactive({
      user: {}
    });
    const user = computed(() => store.state.UserModule.user);
    const currentUser = computed(() => store.getters.currentUser)
    return {
      ...LoadEntity(Actions.LOAD_USER),
      user,
      state,
      currentUser,
      code,
      ...userRoleCheck(),
    }
  },
  methods: {
    onUpdate(user) {
      this.state.user = user;
    },
    onRestPassword(user) {
      Swal.showLoading()
      UserService.resetPassword(user.id).then(() => {
        Swal.fire({
          title: 'Reset Password Success',
          text: '',
          icon: 'success',
          heightAuto: false,
        })
      })
    },
    onSaveUser() {
      const baseModal = this.$refs.userForm as typeof BaseModal
      baseModal.hideBaseModal()
      this.reloadData()
    },

    onSaveMFA() {
      const baseModal = this.$refs.presetMfaForm as typeof BaseModal
      baseModal.hideBaseModal()
      Swal.fire({
        title: 'Preset MFA Success',
        text: '',
        icon: 'success',
        heightAuto: false
      })
      this.reloadData()
    },

    onForceUpdatePassword() {
      const baseModal = this.$refs.refForceUpdatePassword as typeof BaseModal
      baseModal.hideBaseModal()
      Swal.fire({
        title: 'Update password success',
        text: '',
        icon: 'success',
        heightAuto: false
      })
    },
    onUpdateStatus(user, status) {
      UserService.updateStatus(user.id, status).then(() => {
        this.reloadData()
      })
    },
    showMFA(id) {
      Swal.showLoading()
      UserService.showMFA(id).then(res => {
        this.code = res.code;
        const baseModal = this.$refs.refShowMfaCode as typeof BaseModal
        baseModal.showBaseModal();
      }).finally(() => {
        Swal.close()
      })
    },
    buildNav(user) {
      const data = [
        {id: 'login-histories', router: `/users/` + user.id + `/logs`, name: 'Login Sessions'},
        {id: 'roles', router: `/users/` + user.id + `/roles`, name: 'Roles'},
      ]

      data.push({id: 'permissions', router: `/users/` + user.id + `/permissions`, name: 'Permissions'})

      data.push(
        {id: 'action-log', router: `/users/` + user.id + `/action-log`, name: 'Action Log'},
        {id: 'userAnalytics', router: `/users/` + user.id + `/analytics`, name: 'Analytics'},
      )
      return data;
    }
  }
})
